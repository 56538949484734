<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" title="天数计算" fixed>
      <fb-button
        v-if="!isShare"
        slot="left"
        icon="back"
        @click="backToNative()"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
      <div slot="right">
        <tool-head type="computeDays" @clickItem="clickItem">
          <font-size-comp @setFontSize="setFontSize" ref="fontSizeComp" />
        </tool-head>
      </div>
    </fb-header>
    <div id="tools" class="tools-idCard" style="font-size: 14px !important">
      <div class="tools-idCard-title"></div>
      <div class="box" style="padding: 0px 20px">
        <div class="box-left">
          日期
        </div>
        <div class="box-right" style="flex:1;">
          <div class="box-right-time" @click="showChangeTime('startTimeShow')">
            <span v-show="startTime">{{ startTime }}</span>
            <span style="color:#CCC" v-show="!startTime">选择开始时间</span>
          </div>
          <div>至</div>
          <div class="box-right-time" @click="showChangeTime('endTimeShow')">
            <span v-show="endTime">{{ endTime }}</span>
            <span style="color:#CCC" v-show="!endTime">选择结束时间</span>
          </div>
        </div>
      </div>
      <div class="tools-result" v-show="result">
        <div class="tools-result-box">
          <span style="flex:1;margin-right: 10px">{{ result }}</span>
          <i class="iconfont icon-fuzhi" @click="copy"></i>
        </div>
      </div>
      <div class="interest-result">
        <span></span>
        <span>
          <span @click="share" style="margin-right:10px">分享</span>
          <span @click="feedback">反馈</span>
        </span>
      </div>
    </div>
    <van-popup v-model="startTimeShow" position="top">
      <van-datetime-picker
        v-model="startTimeDate"
        type="date"
        title="选择开始时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="startTimeConfirm"
        @cancel="startTimeShow = false"
      />
    </van-popup>
    <van-popup v-model="endTimeShow" position="top">
      <van-datetime-picker
        v-model="endTimeDate"
        type="date"
        title="选择结束时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="endTimeConfirm"
        @cancel="endTimeShow = false"
      />
    </van-popup>
  </fb-page>
</template>
<script>
import longUrl2ShortUrl from '@/common/longUrl2ShortUrlUtils.js'
import fontSizeComp from '../../components/font-size-comp.vue'
import toolHead from '../../components/toolHead.vue'
import goBackMixin from '@mixins/goBack'
export default {
  name: 'compute-days',
  mixins: [goBackMixin],
  components: {
    fontSizeComp,
    toolHead
  },
  data() {
    return {
      isShare: false,
      startTime: '',
      endTime: '',
      show: false,
      resultObj: '',
      result: '',
      startTimeShow: false,
      endTimeShow: false,
      startTimeDate: new Date(),
      endTimeDate: new Date(),
      maxDate: new Date(2100, 12, 31),
      minDate: new Date(1900, 1, 1)
    }
  },
  computed: {},
  methods: {
    clickItem(item) {
      this.addHistory()
      const { condition, type } = item
      const conditionObj = JSON.parse(condition)
      console.log(conditionObj)
      if (type === 'computeDays') {
        const { startTime, endTime } = conditionObj
        this.startTime = startTime
        this.endTime = endTime
      }
    },
    addHistory() {
      const type = 'computeDays'
      const { startTime, endTime } = this
      const conditionObj = {
        startTime,
        endTime
      }
      let title = ' ' + startTime + ' 至 ' + endTime
      title += ' 天数计算'
      if (this.result) {
        this.$axios
          .post(`${this.$base}/management/utils/history/add`, {
            type,
            condition: JSON.stringify(conditionObj),
            title
          })
          .then(res => {
            if (res.data.code === 200) {
              // this.$bus.$emit('add-history-suc', 'computeDays')
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    // 设置字体大小
    setFontSize() {},
    showChangeTime(type) {
      this[type] = true
    },
    // 时间戳转换成时间
    timestampToTime(date) {
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
      return Y + M + D
    },
    // 开始时间选择器点击确定事件
    startTimeConfirm(date) {
      this.startTimeDate = date
      this.startTime = this.timestampToTime(date)
      this.startTimeShow = false
    },
    // 结束时间选择器点击确定事件
    endTimeConfirm(date) {
      this.endTimeDate = date
      this.endTime = this.timestampToTime(date)
      this.endTimeShow = false
    },
    //  查询
    getResult() {
      if (this.startTime !== '' && this.endTime !== '') {
        if (!this.startTime) {
          return this.notify('开始时间不能为空')
        }
        if (!this.endTime) {
          return this.notify('结束时间不能为空')
        }
        if (
          new Date(this.startTime).getTime() > new Date(this.endTime).getTime()
        ) {
          return this.notify('结束时间必须大于开始时间')
        }
        const dateTime =
          new Date(this.endTime).getTime() - new Date(this.startTime).getTime()
        const day = parseInt(dateTime / 1000 / 60 / 60 / 24)
        this.result = `${this.startTime}——${this.endTime}  间隔${day}天`
        this.resultObj = {
          startTime: this.startTime,
          endTime: this.endTime
        }
      }
    },
    // 点击复制图标
    copy() {
      const text = this.result
      const transfer = document.createElement('input')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中'
      })
      this.addHistory()
    },
    // 点击分享
    async share() {
      let text = window.location.href
      if (this.result) {
        const obj = {
          startTime: this.resultObj.startTime,
          endTime: this.resultObj.endTime
        }
        text += `?data=${JSON.stringify(obj)}`
      }
      const res = await longUrl2ShortUrl(text)
      if (res.status === 200) {
        text = res.data
      }
      let title = ''
      if (this.result) {
        title = `${this.result}`
      } else {
        title = '天数计算'
      }
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes(
          title,
          '律呗天数计算',
          window.encodeURI(text)
        )
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: title,
            url: window.encodeURI(text)
          })
        )
      } else {
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          message: '已复制本网页地址，请粘贴到QQ或微信中'
        })
      }
      this.addHistory()
    },

    // 点击反馈
    feedback() {
      this.$router.push({
        name: 'feedback'
      })
    }
  },
  watch: {
    startTime(val) {
      this.getResult()
    },
    endTime(val) {
      this.getResult()
    }
  },
  created() {
    if (this.$route.query.data) {
      const data = JSON.parse(this.$route.query.data)
      this.startTime = data.startTime
      this.endTime = data.endTime
      this.getResult()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.fontSizeComp.fontSize = 20
      this.$refs.fontSizeComp.setFontSize()
    })
  }
}
</script>
<style lang="stylus">
.tools-idCard
  padding-top 80px
  box-sizing border-box
  & .box
    box-sizing border-box
    width 100%
    display flex
    align-items center
    justify-content space-between
    border-bottom 1px solid #CCC
    & .van-cell
      padding 10px 0px
    & .van-radio-group
      display flex
      align-items center
    & .box-left
      & .input-box
        width 60px
      & .van-switch
        font-size 16px
    & .box-right
      display flex
      flex-direction row
      align-items center
      justify-content flex-end
      flex-wrap wrap
      & .input-box
        width 60px
      & .box-right-time
        flex 1
        text-align center
        height 46px
        line-height 46px
      & .other-item
        padding 5px 10px
        margin-top 5px
        margin-left 10px
        box-sizing border-box
        background rgba(0,0,0,0.5)
        color #FFF
  & .tools-idCard-title
    margin-bottom 20px
    font-size 18px !important
    text-align center
  & .tools-idCard-btn
    margin-top 20px
    display flex
    flex-direction row
    align-items center
    justify-content center
  & .tools-result
    & .tools-result-box
      display flex
      align-items center
      justify-content space-between
      padding 5px 15px
      & > span
        color red
  & .interest-result
    box-sizing border-box
    padding 0 20px
    margin-top 20px
    display flex
    flex-direction row
    align-items center
    color red
    justify-content space-between
    & > span
      cursor pointer
      color #0079FE
</style>

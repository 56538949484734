var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "天数计算", fixed: "" },
          slot: "header",
        },
        [
          !_vm.isShare
            ? _c(
                "fb-button",
                {
                  attrs: {
                    slot: "left",
                    icon: "back",
                    size: "small",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.backToNative()
                    },
                  },
                  slot: "left",
                },
                [_vm._v(" 返回 ")]
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "tool-head",
                {
                  attrs: { type: "computeDays" },
                  on: { clickItem: _vm.clickItem },
                },
                [
                  _c("font-size-comp", {
                    ref: "fontSizeComp",
                    on: { setFontSize: _vm.setFontSize },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tools-idCard",
          staticStyle: { "font-size": "14px !important" },
          attrs: { id: "tools" },
        },
        [
          _c("div", { staticClass: "tools-idCard-title" }),
          _c(
            "div",
            { staticClass: "box", staticStyle: { padding: "0px 20px" } },
            [
              _c("div", { staticClass: "box-left" }, [_vm._v(" 日期 ")]),
              _c(
                "div",
                { staticClass: "box-right", staticStyle: { flex: "1" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box-right-time",
                      on: {
                        click: function ($event) {
                          return _vm.showChangeTime("startTimeShow")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.startTime,
                              expression: "startTime",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.startTime))]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.startTime,
                              expression: "!startTime",
                            },
                          ],
                          staticStyle: { color: "#CCC" },
                        },
                        [_vm._v("选择开始时间")]
                      ),
                    ]
                  ),
                  _c("div", [_vm._v("至")]),
                  _c(
                    "div",
                    {
                      staticClass: "box-right-time",
                      on: {
                        click: function ($event) {
                          return _vm.showChangeTime("endTimeShow")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.endTime,
                              expression: "endTime",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.endTime))]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.endTime,
                              expression: "!endTime",
                            },
                          ],
                          staticStyle: { color: "#CCC" },
                        },
                        [_vm._v("选择结束时间")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.result,
                  expression: "result",
                },
              ],
              staticClass: "tools-result",
            },
            [
              _c("div", { staticClass: "tools-result-box" }, [
                _c(
                  "span",
                  { staticStyle: { flex: "1", "margin-right": "10px" } },
                  [_vm._v(_vm._s(_vm.result))]
                ),
                _c("i", {
                  staticClass: "iconfont icon-fuzhi",
                  on: { click: _vm.copy },
                }),
              ]),
            ]
          ),
          _c("div", { staticClass: "interest-result" }, [
            _c("span"),
            _c("span", [
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "10px" },
                  on: { click: _vm.share },
                },
                [_vm._v("分享")]
              ),
              _c("span", { on: { click: _vm.feedback } }, [_vm._v("反馈")]),
            ]),
          ]),
        ]
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.startTimeShow,
            callback: function ($$v) {
              _vm.startTimeShow = $$v
            },
            expression: "startTimeShow",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              title: "选择开始时间",
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.startTimeConfirm,
              cancel: function ($event) {
                _vm.startTimeShow = false
              },
            },
            model: {
              value: _vm.startTimeDate,
              callback: function ($$v) {
                _vm.startTimeDate = $$v
              },
              expression: "startTimeDate",
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.endTimeShow,
            callback: function ($$v) {
              _vm.endTimeShow = $$v
            },
            expression: "endTimeShow",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              title: "选择结束时间",
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.endTimeConfirm,
              cancel: function ($event) {
                _vm.endTimeShow = false
              },
            },
            model: {
              value: _vm.endTimeDate,
              callback: function ($$v) {
                _vm.endTimeDate = $$v
              },
              expression: "endTimeDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }